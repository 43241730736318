<template>
  <div class="h-100 linkchat-wrapper">
    <!--    <div class="mirotalk-timer" v-if="roomsSecondsLeft">{{ fancyTimeFormat(roomsSecondsLeft) }}</div>-->
    <div class="d-md-none d-block mob-logo">
      <img src="/images/logo_call.png" alt=""/>
    </div>
    <a class="d-md-none mob-menu"
       v-if="host"
       @click="$store.commit('toggleShowMobileTopRight')">
      <MenuSvg class="svg-gray"/>
    </a>
    <div id="jaas"></div>
  </div>
</template>

<script>

import { fancyTimeFormat } from "../../../helpers/fancyTimeFormat"
import EventBus from '@/services/event-bus'
import MenuSvg from '@/assets/svg/menu.svg'
import {mapState} from "vuex"
import { loadScript } from "vue-plugin-load-script"
import { JaasService } from "../../../services/api.service"
import moment from 'moment-timezone'
import {isElectron} from "mobile-device-detect"

export default {
  name: "JassIframe",
  props: {
    lesson: {},
  },
  components: {
    MenuSvg
  },
  computed: {
    ...mapState({
      host: state => state.call.host,
      hostId: state => state.call.hostId,
      lessonId: state => state.call.lessonId,
      linkchatUrl: state => state.call.linkchatUrl,
      studentsBySubrooms:   state => state.call.studentsBySubrooms,
      roomsSecondsLeft: state => state.call.roomsSecondsLeft,
      showIframe: state => state.call.showIframe,
      user: state => state.user,
      room: state => state.call.room,
      school: state => state.call.school,
      group: state => state.call.group,
    }),
    subroom() {
      if(!this.user) return null
      return this.studentsBySubrooms && this.studentsBySubrooms[this.user.id] ? this.studentsBySubrooms[this.user.id] : null
    },
    link() {
      return this.host ? this.hostLink : this.linkchatUrl
    }
  },
  mounted() {
    this.launch()
  },
  data() {
    return {
      hostLink: null,
      jaasDomain: '8x8.vc'
    }
  },
  methods: {
    async launch() {
      this.mirotalkLink()
    },
    async mirotalkLink() {
      const appId = this.group?.meta_data?.jaasOn ? this.group?.meta_data?.jaasAppId : this.school?.meta_data?.jaasAppId
      const roomName = `${appId}/${this.room}`
      await loadScript(`https://${this.jaasDomain}/external_api.js`)
      const res = await JaasService.getJWT({
        "aud": "jitsi",
        "iss": "chat",
        "exp": moment().add(2, 'hours').unix(),
        "iat": moment().add(2, 'hours').unix(),
        "nbf": moment().unix(),
        "context": {
          "features": {
            "livestreaming": this.host,
            "outbound-call": this.host,
            "sip-outbound-call": this.host,
            "transcription": true,
            "recording": this.host
          },
          "user": {
            "hidden-from-recorder": false,
            "moderator": this.host,
            "name": this.user?.name || 'Student',
            "id": this.user?.id || 'demo',
            "avatar": "",
            "email": this.user?.email || '',
          }
        },
        // "room": this.room
        "room": '*'
      }, this.lessonId)
      const jwt = res.data.data.jwt
      const api = new window.JitsiMeetExternalAPI(this.jaasDomain, {
        parentNode: document.querySelector('#jaas'),
        roomName,
        jwt,
        configOverwrite: {
          // startWithAudioMuted: false,
          whiteboard: {
              enabled: true,
              collabServerBaseUrl: 'https://excalidraw-backend-pilot.jitsi.net'
          },
          // toolbarButtons: [
          //    'camera',
          //    'chat',
          //    'closedcaptions',
          //    'desktop',
          //    'download',
          //    'embedmeeting',
          //    'etherpad',
          //    'feedback',
          //    'filmstrip',
          //    'fullscreen',
          //    'hangup',
          //    'help',
          //    'highlight',
          //    'invite',
          //    'linktosalesforce',
          //    'livestreaming',
          //    'microphone',
          //    'noisesuppression',
          //    'participants-pane',
          //    'profile',
          //    'raisehand',
          //    'recording',
          //    'security',
          //    'select-background',
          //    'settings',
          //    'shareaudio',
          //    'sharedvideo',
          //    'shortcuts',
          //    'stats',
          //    'tileview',
          //    'toggle-camera',
          //    'videoquality',
          //    'whiteboard',
          // ],
        }
      })
      if(isElectron) {
        window.setupJitsiScreenSharing(api)
      }
    },
    fancyTimeFormat(duration) {
      return fancyTimeFormat(duration)
    }
  },
  watch: {
    linkchatUrl() {
      this.$nextTick(() => {
        EventBus.$emit('restyle')
        if(this.host && !this.mirotalk) {
          this.$parent.sendStatusDataToSockets()
        }
      })
    },
    subroom() {
      this.$nextTick(() => {
        this.mirotalkLink()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.linkchat-wrapper {
  font-size: 0;
  position: relative;
  z-index: 7;
  .header__button {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 2;
    background: #F5F6F8;
    border: none;
    vertical-align: middle;
    border-radius: 6px;
    width: 32px;
    height: 32px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  #jaas {
    width: 100%;
    height: 100vh;
    border: none;
    &.hidden {
      visibility: hidden;
    }
  }
}
.mirotalk-timer {
  position: absolute;
  z-index: 10;
  top: 10px;
  right: 10px;
  font-size: 12px;
  display: inline-block;
  background: $text-warning;
  color: #856404;
  padding: 1px 5px;
  text-align: center;
  width: 48px;
  border-radius: 7px;
}
@media(max-width: 768px) {
  .linkchat-wrapper {
    padding-left: 0;
    padding-right: 0;
    #jaas {
      width: 100%;
      height: calc(100vh - 64px);
    }
    &.half {
      #jaas {
        height: calc(35vh - 32px);
      }
    }
    &.fourth {
      #jaas {
        height: 0;
      }
    }
  }
  .mob-logo {
    position: absolute;
    left: 15px;
    top: 10px;
    img {
      width: 31px;
    }
  }
  .mob-menu {
    position: absolute;
    right: 15px;
    top: 18px;
  }
}
</style>
