<template>
  <div class="h-100 linkchat-wrapper">
    <div class="mirotalk-timer" v-if="roomsSecondsLeft">{{ fancyTimeFormat(roomsSecondsLeft) }}</div>
    <div class="d-md-none d-block mob-logo">
      <img src="/images/logo_call.png" alt=""/>
    </div>
    <a class="d-md-none mob-menu"
       v-if="host"
       @click="$store.commit('toggleShowMobileTopRight')">
      <MenuSvg class="svg-gray"/>
    </a>
    <iframe id="linkchat"
            allow="camera *; microphone *; fullscreen; display-capture; clipboard-write"
            :class="{ hidden: !showIframe}"
            v-if="linkchatUrl"
            :src="link"></iframe>
  </div>
</template>

<script>

import {LessonsService} from "../../../services/api.service"
import { fancyTimeFormat } from "../../../helpers/fancyTimeFormat"
import EventBus from '@/services/event-bus'
import MenuSvg from '@/assets/svg/menu.svg'
import {mapState} from "vuex"

export default {
  name: "LinkChat",
  props: {
    lesson: {},
    mirotalk: {},
  },
  components: {
    MenuSvg
  },
  computed: {
    ...mapState({
      host: state => state.call.host,
      hostId: state => state.call.hostId,
      linkchatUrl: state => state.call.linkchatUrl,
      studentsBySubrooms:   state => state.call.studentsBySubrooms,
      roomsSecondsLeft: state => state.call.roomsSecondsLeft,
      showIframe: state => state.call.showIframe,
      user: state => state.user,
      room: state => state.call.room,
    }),
    subroom() {
      if(!this.user) return null
      return this.studentsBySubrooms && this.studentsBySubrooms[this.user.id] ? this.studentsBySubrooms[this.user.id] : null
    },
    link() {
      return this.host ? this.hostLink : this.linkchatUrl
    }
  },
  mounted() {
    this.launch()
  },
  data() {
    return {
      hostLink: null
    }
  },
  methods: {
    async launch() {
      if(this.mirotalk) {
        this.mirotalkLink()
      } else {
        await this.createLinkchatUrl()
      }
    },
    async createLinkchatUrl() {
      if(this.host) {
        let res = await LessonsService.generateLinkchatLink(this.lesson.id)
        this.$store.commit('setLinkchatUrl', res.data.data.guestLink)
        this.hostLink = res.data.data.hostLink
      }
    },
    mirotalkLink() {
      let userId = 'demo'
      let userName = 'Student'
      if(this.$store.state.user) {
        userId = this.$store.state.user.id
        userName = this.$store.state.user.name
      }
      let params = {
        room: this.room+'-'+this.subroom,
        host: this.host,
        userId: userId,
        name: userName,
        hostId: this.hostId
      }
      params = new URLSearchParams(params).toString()
      let sfuLink = `https://sfu-vpc.study.enggo.kz/${btoa(params)}`
      // let sfuLink = `https://localhost:4443/${btoa(params)}`
      // let sfuLink = `https://pssfu.study.enggo.kz/${btoa(params)}`
      // let sfuLink = this.host ? `https://localhost:4443/${btoa(params)}` : `https://sfu.study.enggo.kz/${btoa(params)}`
      this.$store.commit('setLinkchatUrl', sfuLink)
      this.hostLink = sfuLink
    },
    fancyTimeFormat(duration) {
      return fancyTimeFormat(duration)
    }
  },
  watch: {
    linkchatUrl() {
      this.$nextTick(() => {
        EventBus.$emit('restyle')
        if(this.host && !this.mirotalk) {
          this.$parent.sendStatusDataToSockets()
        }
      })
    },
    subroom() {
      this.$nextTick(() => {
        this.mirotalkLink()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.linkchat-wrapper {
  font-size: 0;
  position: relative;
  z-index: 7;
  .header__button {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 2;
    background: #F5F6F8;
    border: none;
    vertical-align: middle;
    border-radius: 6px;
    width: 32px;
    height: 32px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  iframe {
    width: 100%;
    height: 100vh;
    border: none;
    &.hidden {
      visibility: hidden;
    }
  }
}
.mirotalk-timer {
  position: absolute;
  z-index: 10;
  top: 10px;
  right: 10px;
  font-size: 12px;
  display: inline-block;
  background: $text-warning;
  color: #856404;
  padding: 1px 5px;
  text-align: center;
  width: 48px;
  border-radius: 7px;
}
@media(max-width: 768px) {
  .linkchat-wrapper {
    padding-left: 0;
    padding-right: 0;
    iframe {
      width: 100%;
      height: calc(100vh - 64px);
    }
    &.half {
      iframe {
        height: calc(35vh - 32px);
      }
    }
    &.fourth {
      iframe {
        height: 0;
      }
    }
  }
  .mob-logo {
    position: absolute;
    left: 15px;
    top: 10px;
    img {
      width: 31px;
    }
  }
  .mob-menu {
    position: absolute;
    right: 15px;
    top: 18px;
  }
}
</style>
